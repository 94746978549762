.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  gap: 1rem;
  color: white;
  padding: 8px;
  font-size: large;
  font-weight: bold;
}

.Nav-container {
  width: 100%;
}

.Nav-link {
  float: left;
  color:white;
  text-decoration: none;
  margin: 10px;
}

.Nav-link:hover, .Nav-link-right:hover {
  color:coral;
}

.Nav-link-right {
  float: right;
  color:white;
  text-decoration: none;
  margin: 10px;
 }

 .active{
  color:aqua;
 }

 a {
  color: #09578f; /* hyperlink text color */
  text-decoration: none; /* remove underline */
  font-weight: bold; /* make text bold */
}

a:hover {
  color: #c676be; /* hyperlink text color on hover */
  text-decoration: underline; /* add underline on hover */
}